/**
 * 定义路由地址.
 * */
const routePath = {
  CUSTOM: "custom",
  custom: {
    HOME: "home",
    ORDER: "order",
    RETRIEVE: "retrieve",
  },
  WCMS: "wcms",
  wcms: {
    LOGIN: "login",
    JOIN: "join",
    FILL_INFO: "fillInfo",
    DASHBOARD: "dashboard",
    HANDLE_ORDER: "handle-order",
    ANNOUNCE_MANAGE: "announce-manage",
    SELF_PROFILE: "profile",
    MEMBER_MANAGE: "member-manage",
    EXP: "exp",
    CHART_STAT: "stat",
    WTF1: "wtf1"
  },
  OAUTH: "xoauth",
};

export { routePath };
