import React, { useMemo } from "react";
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";
import { routePath } from "@/page/routePath";
import { CalendarOutlined, FileSearchOutlined, HomeOutlined, SolutionOutlined, } from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { Footer as MyFooter } from "@/components/footer";
import { CustomHomePage } from "./home";
import { RequestOrderPage } from "./requestOrder";
import { PageNotFound } from "@/components/notFound";
import { CenterMeResponsive } from "@/components/layout";
import { useTitle } from "@/hook/useTitle";
import { RetrieveOrder } from "@/page/custom/retrieveOrder";
import { CustomContextProvider } from "@/page/custom/CustomContext";

const { Header, Content, Footer } = Layout;

function CustomRouter() {
  return (
    <Routes>
      <Route
        path={"/"}
        element={
          <Navigate
            to={`/${routePath.CUSTOM}/${routePath.custom.HOME}`}
            replace
          />
        }
      />
      <Route path={`/${routePath.custom.HOME}`} element={<CustomHomePage />} />
      <Route
        path={`/${routePath.custom.ORDER}`}
        element={<RequestOrderPage />}
      />
      <Route
        path={`/${routePath.custom.RETRIEVE}`}
        element={<RetrieveOrder />}
      />
      <Route path={`/`} element={<PageNotFound />} />
    </Routes>
  );
}

function CustomSystem() {
  useTitle("IT侠预约系统");
  return (
    <CustomContextProvider>
      <Layout id="home">
        <Header id="home-header">
          <span id="home-header-text">NJU IT侠预约系统</span>
          <div id="home-header-navi" style={{ flexGrow: 0.3 }}>
            <CustomNavigate />
          </div>
        </Header>
        <Content id="home-content">
          <CenterMeResponsive small>
            <CustomRouter />
          </CenterMeResponsive>
        </Content>
        <Footer>
          <MyFooter />
        </Footer>
      </Layout>
    </CustomContextProvider>
  );
}

function CustomNavigate() {
  const location = useLocation();
  const items = useMemo(() => {
    return [
      {
        key: "wtf", // <- zhenxi 学长干的
        icon: <CalendarOutlined />,
        label: "预约系统",
        children: [
          {
            key: routePath.custom.HOME,
            icon: <HomeOutlined />,
            label: (
              <Link to={`/${routePath.CUSTOM}/${routePath.custom.HOME}`}>
                主页
              </Link>
            ),
          },
          {
            key: routePath.custom.ORDER,
            icon: <CalendarOutlined />,
            label: (
              <Link to={`/${routePath.CUSTOM}/${routePath.custom.ORDER}`}>
                发起预约
              </Link>
            ),
          },
          {
            key: routePath.custom.RETRIEVE,
            icon: <FileSearchOutlined />,
            label: (
              <Link to={`/${routePath.CUSTOM}/${routePath.custom.RETRIEVE}`}>
                找回预约单
              </Link>
            ),
          },
        ],
      },
      {
        key: routePath.WCMS,
        icon: <SolutionOutlined />,
        label: <Link to={`/${routePath.WCMS}`}>后台系统</Link>,
      },
    ];
  }, []);

  return (
    <Menu
      mode="horizontal"
      theme="dark"
      selectedKeys={[location.pathname.split("/").slice(-1)[0] || ""]}
      items={items}
    />
  );
}

export { CustomSystem };
