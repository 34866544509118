import React from "react";
import DatePicker from "@/page/wcms/handleOrder/DatePicker";
import { Avatar, Card, Col, Divider, Row } from "antd";
import { CrownFilled } from '@ant-design/icons';
import { Loading } from "@/components/loading";
import { useApiRequest } from "@/hook/useApiRequest";
import "./charts.css"
import { useMount } from "@/hook";
import dayjs from "dayjs";

export const Charts: React.FC = () => {
  const [avatarColor, setAvatarColor] = React.useState([[0, 0], [0, 0], [0, 0]]);

  const { code, payload, sendRequest } = useApiRequest({
    path: `/itxiaStat/juan`,
    manual: true,
    onSuccess: () => {
      setAvatarColor([
        (payload?.[0] ? getColor(payload?.[0].itxiaMember.realName) : [0, 0]),
        (payload?.[1] ? getColor(payload?.[1].itxiaMember.realName) : [0, 0]),
        (payload?.[2] ? getColor(payload?.[2].itxiaMember.realName) : [0, 0]),
      ]);
    }
  });

  const refreshCharts = (begin: String, end: String) => {
    sendRequest({
      requestQuery: {
        startDate: begin,
        endDate: end,
      }
    })
  }

  useMount(() => refreshCharts(new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000).toISOString().substring(0, 10), new Date().toISOString().substring(0, 10)));

  const { RangePicker } = DatePicker;

  let Charts = (
    <>
      <Row align={"bottom"} gutter={[12, 0]}>
        {/*TODO: 姓名和接单量字体在深色模式下的颜色不会切换*/}
        <Col span={8}>
          <div className="t3Col">
            <Avatar size={48} style={{
              color: `hsl(${avatarColor[1][0]},${avatarColor[1][1]}%,50%)`,
              backgroundColor: `hsl(${avatarColor[1][0]},${avatarColor[2][1]}%,92%)`
            }}>{payload?.[1]?.itxiaMember.realName[0] || ''}</Avatar>
            <span className="t3OrderCount">{payload?.[1] ? payload?.[1].orderCount + " 单" : "--"}</span>
            <span className="t3NameLine">
              {payload?.[1] ? (<CrownFilled style={{ color: "#a9a9a9", marginRight: "4px" }} />) : null}
              <span className="t3Name">{payload?.[1]?.itxiaMember.realName || "虚位以待"}</span>
            </span>
          </div>
        </Col>
        <Col span={8}>
          <div className="t3Col">
            <Avatar size={56} style={{
              color: `hsl(${avatarColor[0][0]},${avatarColor[0][1]}%,50%)`,
              backgroundColor: `hsl(${avatarColor[0][0]},${avatarColor[0][1]}%,92%)`
            }}>{payload?.[0]?.itxiaMember.realName[0] || ''}</Avatar>
            <span className="t3OrderCount">{payload?.[0] ? payload?.[0].orderCount + " 单" : "--"}</span>
            <span className="t3NameLine">
                {payload?.[0] ? (
                  <CrownFilled style={{ color: "#eeb605", fontSize: "1.25em", marginRight: "4px" }} />) : null}
              <span className="t3Name">{payload?.[0]?.itxiaMember.realName || "虚位以待"}</span>
              </span>
          </div>
        </Col>
        <Col span={8}>
          <div className="t3Col">
            <Avatar size={48} style={{
              color: `hsl(${avatarColor[2][0]},${avatarColor[2][1]}%,50%)`,
              backgroundColor: `hsl(${avatarColor[2][0]},${avatarColor[2][1]}%,92%)`
            }}>{payload?.[2]?.itxiaMember.realName[0] || ''}</Avatar>
            <span className="t3OrderCount">{payload?.[2] ? payload?.[2].orderCount + " 单" : "--"}</span>
            <span className="t3NameLine">
                {payload?.[2] ? (<CrownFilled style={{ color: "#dc8e3b", marginRight: "4px" }} />) : null}
              <span className="t3Name">{payload?.[2]?.itxiaMember.realName || "虚位以待"}</span>
              </span>
          </div>
        </Col>
      </Row>
      {payload && payload.length > 3 ? (<Divider />) : ""}
      <div className="others">
        {
          payload?.slice(3).map((item: { itxiaMember: ItxiaMember, orderCount: number }, index: number) => (
            <div className="chartsLine" key={index}>
              <span className="rank">{4 + index}</span>
              <span className="name">{item.itxiaMember.realName}</span>
              <span className="orderCount">{item.orderCount + " 单"}</span>
            </div>
          ))
        }
      </div>
    </>
  )

  return (
    <Card>
      <h1 style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "1em" }}>
        <span style={{ flex: "0 0 auto", marginRight: "1em" }}>卷王排行</span>
        {/*TODO: 解决 RangePicker 语言为英语的问题*/}
        <RangePicker
          defaultValue={[dayjs(new Date().getTime() - 30 * 24 * 60 * 60 * 1000), dayjs(Date.now())]}
          allowClear={false}
          onChange={e => {
            refreshCharts(e![0]!.format("YYYY-MM-DD"), e![1]!.format("YYYY-MM-DD"));
          }}
        />
      </h1>

      {code ? (<Loading />) : Charts}
    </Card>
  );
};

interface ItxiaMember {
  realName: String
}

function getColor(name: String): [number, number] {
  let number: number = 0;
  for (let i = 0; i < name.length; i++) {
    number += +name.charCodeAt(i).toString(10);
  }
  return [number % 360, (number % 100 > 30 ? number % 100 : 30)];
}
