import React from "react";
import { Alert, Button, Card, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { routePath } from "@/page/routePath";
import { useTitleCustom } from "@/hook/useTitle";
import { CenterMeResponsive } from "@/components/layout";
import { useCustomContext } from "@/page/custom/CustomContext";

export const CustomHomePage: React.FC = () => {
  useTitleCustom("主页");

  const navigate = useNavigate();

  function handleGoToOrder() {
    window.scrollTo(0, 0);
    navigate(`/${routePath.CUSTOM}/${routePath.custom.ORDER}`);
  }

  function handleRetrieveOrder() {
    navigate(`/${routePath.CUSTOM}/${routePath.custom.RETRIEVE}`);
  }

  const customContext = useCustomContext();

  let orderButtonText = "发起预约";
  if (customContext.hasOrder()) {
    orderButtonText = "查看我的预约";
  }

  const orderNotification = (
    <ul style={{ margin: "0.5em", lineHeight: "2.3", paddingInlineStart: "20px" }}>
      {!customContext.hasOrder() && (
        <li>
          已有预约？
          <button
            className="link-like-button"
            type="button"
            onClick={handleRetrieveOrder}
          >
            找回预约单
          </button>
        </li>
      )}
      <li>
        预约之前，请查看我们的
        <a
          rel="noopener noreferrer"
          href="https://itxia.club/service#TOS"
          target="_blank"
        >
          服务条款
        </a>
      </li>
      <li>
        加入
        <a
          rel="noopener noreferrer"
          href="https://itxia.club/service#groups"
          target="_blank"
        >
          IT侠互助QQ群
        </a>
        在线寻找帮助
      </li>
    </ul>
  );

  return (
    <Card title="预约维修">
      <Row gutter={24} justify="center" align="middle">
        <Col xs={24} sm={16} md={10} lg={10}
             style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <img
            src="/img/itxia-logo.png"
            alt="logo"
            style={{ display: "block", width: "100%", maxWidth: "200px", height: "auto" }}
            className="itxia-logo"
          />
          <Button type="primary" onClick={handleGoToOrder} style={{
            marginTop: "30px",
            marginBottom: "20px",
            height: "56px",
            width: "160px",
            borderRadius: "10px",
            boxShadow: "0 10px 16px rgba(25, 100, 255, 0.2)",
            fontWeight: "bold",
            fontSize: "20px",
          }}>
            {orderButtonText}
          </Button>
        </Col>
        <Col xs={24} sm={18} md={12} lg={10}
             style={{ justifyContent: "center", alignItems: "center", marginTop: "20px", marginBottom: "20px" }}>
          <div style={{ cursor: "pointer", width: "100%", textAlign: "left", }}>
            <CenterMeResponsive>
              <Alert type="info" message={orderNotification} style={{ width: "100%", }} />
            </CenterMeResponsive>
          </div>
        </Col>
      </Row>
    </Card>
  );
};
