import React from "react";
import { Form, Input, Switch } from "antd";
import { RecordTagSelect } from "./RecordTagSelect";

interface SearchConditionProps {
  onConditionChange: (values: any) => void;
}

export const SearchCondition: React.FC<SearchConditionProps> = ({
  onConditionChange,
}) => {
  return (
    <Form
      onValuesChange={(changedValues, allValues) => {
        onConditionChange(allValues);
      }}
      labelCol={{ span: 5 }}
    >
      <Form.Item name="onlyMine" label="只看我的" valuePropName="checked">
        <Switch />
      </Form.Item>

      <Form.Item name="onlyStar" label="只看收藏" valuePropName="checked">
        <Switch />
      </Form.Item>

      <Form.Item name="tags" label="标签">
        <RecordTagSelect />
      </Form.Item>

      <Form.Item name="text" label="搜索记录">
        <Input
          allowClear
          placeholder="标题、内容、记录者姓名……"
        />
      </Form.Item>
    </Form>
  );
};
