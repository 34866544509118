import React, { useMemo, useState } from "react";
import { MenuOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import { EmbeddableLoading, Loading } from "@/components/loading";
import { utcDateToText } from "@/util/time";
import { AnnouncementType } from "@/util/enum";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from "@dnd-kit/sortable";
import "./table.css";

interface DataItem {
  id: string;
  type: AnnouncementType;
  title: string;
  createTime: string;
}

interface SortableItemProps {
  active: boolean;
  item: DataItem;
  editingAnnounceID: string | null;
  onDeleteAnnounce: (announceID: string) => void;
  onEditAnnounce: (announceID?: string | null) => void;
}

const SortableItem: React.FC<SortableItemProps> = (props) => {
  const {
    active,
    item: { id, type, title, createTime },
    editingAnnounceID,
    onDeleteAnnounce,
    onEditAnnounce,
  } = props;
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id,
    });
  return (
    <tr
      ref={setNodeRef}
      className={"row-base" + (active ? " row-dragging" : "")}
      style={
        {
          transition,
          "--translate-x": transform
            ? `${Math.round(transform.x)}px`
            : undefined,
          "--translate-y": transform
            ? `${Math.round(transform.y)}px`
            : undefined,
          "--scale-x": transform?.scaleX ? `${transform.scaleX}` : undefined,
          "--scale-y": transform?.scaleY ? `${transform.scaleY}` : undefined,
        } as React.CSSProperties
      }
      {...attributes}
    >
      <td className={"ant-table-cell"} style={{ width: 48 }}>
        <MenuOutlined
          style={{ cursor: "pointer", color: "#999" }}
          {...(editingAnnounceID ? null : listeners)}
        />
      </td>
      <td className="ant-table-cell">
        {type === "INTERNAL"
          ? "后台管理"
          : type === "EXTERNAL"
            ? "预约系统"
            : ""}
      </td>
      <td className="ant-table-cell">{title}</td>
      <td className="ant-table-cell drag-hidden">
        <span>{utcDateToText(createTime)}</span>
      </td>
      <td className="ant-table-cell">
        <div className="truncate-text">
          {/* 使用TruncateText组件，显式指定text和lines的类型 */}
          <TruncateText text={title} lines={3} />
        </div>

        {/* 如果文本行数超过三行，显示“查看全部”按钮 */}
        {title.split("\n").length > 3 && (
          <Button onClick={toggleExpand}>
            {isExpanded ? "收起" : "查看全部"}
          </Button>
        )}
      </td>
      <td className="ant-table-cell drag-hidden" style={{ minWidth: "182px" }}>
        {editingAnnounceID ? (
          editingAnnounceID === id && (
            <Button
              onClick={() => {
                onEditAnnounce(null);
              }}
              type="primary"
              danger
            >
              取消编辑
            </Button>
          )
        ) : (
          <>
            <Button
              onClick={() => {
                onEditAnnounce(id);
              }}
              style={{ marginRight: "1em" }}
            >
              修改
            </Button>
            <Popconfirm
              title="确定要删除此公告吗?"
              okText="确定"
              cancelText="取消"
              onConfirm={() => {
                onDeleteAnnounce(id);
              }}
            >
              <Button type="primary" danger>
                删除
              </Button>
            </Popconfirm>
          </>
        )}
      </td>
    </tr>
  );
};

// TruncateText组件接受的属性类型
interface TruncateTextProps {
  text: string;
  lines: number;
}

// TruncateText组件，用于处理文本截断逻辑
const TruncateText: React.FC<TruncateTextProps> = ({ text, lines }) => {
  const ellipsis = "...";
  const words = text.split(" ");

  let truncatedText = words.slice(0, lines * 6).join(" "); // 假设每行平均 6 个单词

  if (words.length > lines * 6) {
    truncatedText += ellipsis;
  }

  return <>{truncatedText}</>;
};

interface AnnouncementTableProps {
  loading: boolean;
  //公告信息
  data: any;
  //正在编辑的公告ID
  editingAnnounceID?: string | null;
  //更改公告列表的排序
  onChangeAnnounceOrder: (dataArray: any[]) => void;
  //删除公告
  onDeleteAnnounce: (announceID: string) => void;
  //设置正在编辑的公告
  onEditAnnounce: (announceID?: string | null) => void;
}

/**
 * 公告表格.
 * 展示公告标题、顺序、编辑按钮等等.
 * */
export const AnnouncementTable: React.FC<AnnouncementTableProps> = ({
  loading,
  data,
  editingAnnounceID = null,
  onChangeAnnounceOrder,
  onDeleteAnnounce,
  onEditAnnounce,
}) => {
  const items = useMemo<DataItem[] | null | undefined>(
    () =>
      data?.map((item: any) => ({
        id: item._id,
        type: item.type,
        title: item.title,
        createTime: item.createTime,
      })),
    [data],
  );
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  if (!items) return <Loading />;

  return (
    <EmbeddableLoading loading={loading}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={({ active }) => {
          if (active) {
            setActiveId(active.id);
          }
        }}
        onDragEnd={({ over }) => {
          if (over) {
            const getIndex = (id: UniqueIdentifier) =>
              items.findIndex((item: any) => {
                return item.id === id;
              });
            const activeIndex = activeId ? getIndex(activeId) : -1;
            const overIndex = getIndex(over.id);
            if (activeIndex !== overIndex) {
              onChangeAnnounceOrder(arrayMove(data, activeIndex, overIndex));
            }
          }
          setActiveId(null);
        }}
        onDragCancel={() => setActiveId(null)}
      >
        <SortableContext items={items} strategy={rectSortingStrategy}>
          <div className="ant-table">
            <div className="ant-table-container">
              <table style={{ tableLayout: "auto" }}>
                <thead className="ant-table-thead">
                <tr>
                  <th className="ant-table-cell" style={{ width: 48 }}></th>
                  <th className="ant-table-cell">公告类型</th>
                  <th className="ant-table-cell">标题</th>
                  <th className="ant-table-cell">时间</th>
                  <th className="ant-table-cell">操作</th>
                </tr>
                </thead>
                <tbody className="ant-table-tbody">
                {items.map((value: any) => (
                  <SortableItem
                    key={value.id}
                    active={value.id === activeId}
                    item={{
                      id: value.id,
                      type: value.type,
                      title: value.title,
                      createTime: value.createTime,
                    }}
                    editingAnnounceID={editingAnnounceID}
                    onDeleteAnnounce={onDeleteAnnounce}
                    onEditAnnounce={onEditAnnounce}
                  />
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </SortableContext>
      </DndContext>
    </EmbeddableLoading>
  );
};
