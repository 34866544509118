import React from "react";
import { Button, Card, Form, Input, Modal, Select } from "antd";
import { CenterMeFlex } from "@/components/layout";
import { useNavigate } from "react-router-dom";
import { routePath } from "@/page/routePath";
import "./join.css";
import { useTitleWCMS } from "@/hook/useTitle";
import { useApiRequest } from "@/hook/useApiRequest";
import { MemberGroupEnum } from "@/util/enum";
import { useMemberContext } from "@/hook/useMemberContext";
import { CampusFormItem } from "@/components/form/CampusFormItem";

export const FillInfoPage: React.FC = () => {
  console.log(useMemberContext());
  useTitleWCMS("请补充您的信息");

  const {
    realName,
    loginName,
    studentNumber,
    graduateYear,
    email,
    campus,
    group,
  } = useMemberContext();

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const logOutRequest = useApiRequest({
    path: "/logout",
    manual: true,
  });

  const { loading, sendRequest } = useApiRequest({
    path: "/member/me/profile",
    method: "PUT",
    manual: true,
    popModal: {
      onSuccess: {
        title: "提交成功",
        content: (
          <div>
            <p>感谢您的配合</p>
            <p>请重新登录系统。</p>
          </div>
        ),
        okText: "返回主页",
        onOk: () => {
          navigate(`/${routePath.WCMS}/${routePath.wcms.LOGIN}`);
        },
      },
      onError: true,
    },
    onSuccess: () => {
      logOutRequest.sendRequest();
      form.resetFields();
    },
    onFail: ({ code }) => {
      Modal.error({
        title: "提交失败",
        content: code,
        centered: true,
      });
    },
  });

  function handleSubmit(values: any) {
    sendRequest({ requestBody: values });
  }

  return (
    <div className="join-page-container">
      <Card title="修改信息" className="join-page-card">
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          form={form}
          onFinish={handleSubmit}
          initialValues={{
            realName: realName,
            loginName: loginName,
            studentNumber: studentNumber,
            email: email,
            graduateYear: graduateYear?.toString(),
            campus: campus,
            group: group,
          }}
        >
          <Form.Item name="realName" label="姓名">
            <Input disabled={true} />
          </Form.Item>

          <Form.Item name="loginName" label="账号名">
            <Input disabled={true} />
          </Form.Item>

          <Form.Item
            name="studentNumber"
            label="学号"
            rules={[
              { required: true, message: "请输入你的学号" },
              {
                pattern: /^\w{9,12}$/,
                message: "本科生9位数学号，研究生9/10/12位数学号",
              },
            ]}
            hasFeedback
          >
            <Input placeholder="用于身份验证，本硕升学时更改学号" />
          </Form.Item>

          <Form.Item
            name="graduateYear"
            label="预计毕业时间"
            rules={[
              { required: true, message: "请输入你的预计毕业时间" },
              {
                pattern: /^20[234]\d$/,
                message: "4位数年份",
              },
            ]}
            hasFeedback
          >
            <Input placeholder="用于身份验证" />
          </Form.Item>

          <Form.Item
            name="email"
            label="个人邮箱"
            rules={[
              { required: true, message: "请输入你的个人邮箱" },
              { type: "email", message: "邮箱地址看起来不对..." },
            ]}
            hasFeedback
          >
            <Input placeholder="用来接收一些通知" />
          </Form.Item>

          <CampusFormItem />

          <Form.Item
            name="group"
            label="分组"
            rules={[{ required: true, message: "请填写你的分组" }]}
          >
            <Select placeholder="请填写你的分组">
              <Select.Option value={MemberGroupEnum.GEEK}>geek</Select.Option>
              <Select.Option value={MemberGroupEnum.OP}>op</Select.Option>
              <Select.Option value={MemberGroupEnum.WEB}>web</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item wrapperCol={{ span: 24 }}>
            <CenterMeFlex>
              <Button type="primary" loading={loading} htmlType="submit">
                提交
              </Button>
            </CenterMeFlex>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};
