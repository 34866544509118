import {useApiRequest} from "@/hook";
import {Button, Card, Steps} from "antd";
import {ReactMarkdown} from "@/util/md2html";
import {useState} from "react";
import {OrderForm} from "@/page/custom/requestOrder/OrderForm";
import {WarningModal} from "@/page/custom/requestOrder/WarningModal";
import {Loading} from "@/components/loading";

export const SubmitOrderSteps = () => {
    const path = `/custom/announcement`;
    const {loading, payload} = useApiRequest({
        path,
        formatResult: (data) => {
            //最近的公告排在前面
            return data.sort((foo: any, bar: any) => {
                if (foo.order === bar.order) {
                    return Date.parse(bar.createTime) - Date.parse(foo.createTime);
                }
                return foo.order - bar.order;
            });
        },
        popModal: {
            onFail: true,
            onError: true,
        },
    });

    let steps = (payload || []).map((item: any) => ({
        title: item.title,
        content: (
            <Card>
                <ReactMarkdown>{item.content}</ReactMarkdown>
            </Card>
        )
    }));
    steps.push({
        title: "填写预约单",
        content: <OrderForm/>,
    });

    const [currentStep, setCurrentStep] = useState(0);

    function nextStep() {
        if (currentStep + 1 === steps.length - 1
            && !confirmed) {
            setConfirmVisible(true);
        } else {
            setCurrentStep(currentStep + 1);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
        }
    }

    function previousStep() {
        setCurrentStep(currentStep - 1);
    }

    const [confirmVisible, setConfirmVisible] = useState(false);
    const [confirmed, setConfirmed] = useState(false);

    function handleConfirm() {
        setConfirmVisible(false);
        setConfirmed(true);
        setCurrentStep(currentStep + 1);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }

    return (
        loading
            ?
            <>
                <Loading/>
            </>
            :
            <>
                <Steps items={steps} current={currentStep}/>
                <br/>
                <div>{steps[currentStep].content}</div>
                <div style={{marginTop: 24,}}>
                    {currentStep < steps.length - 1 && (
                        <div style={{float: "right"}}>
                            <Button
                                type="primary"
                                onClick={nextStep}
                                style={{
                                    marginTop: "30px",
                                    marginBottom: "20px",
                                    height: "56px",
                                    width: "128px",
                                    borderRadius: "10px",
                                    boxShadow: "0 10px 16px rgba(25, 100, 255, 0.2)",
                                    fontWeight: "bold",
                                    fontSize: "20px",
                                }}
                            >
                                我已知晓
                            </Button>
                        </div>
                    )}
                    {currentStep > 0 && (
                        <div style={{float: "left"}}>
                            <Button
                                onClick={previousStep}
                                style={{
                                    marginTop: "30px",
                                    marginBottom: "20px",
                                    height: "56px",
                                    width: "128px",
                                    borderRadius: "10px",
                                    boxShadow: "0 10px 16px rgba(0, 0, 0, 0.2)",
                                    fontWeight: "bold",
                                    fontSize: "20px",
                                }}
                            >
                                上一页
                            </Button>
                        </div>
                    )}
                    {currentStep === 0 && (
                        <div style={{float: "left"}}>
                            <Button
                                disabled={true}
                                onClick={previousStep}
                                style={{
                                    marginTop: "30px",
                                    marginBottom: "20px",
                                    height: "56px",
                                    width: "128px",
                                    borderRadius: "10px",
                                    boxShadow: "0 10px 16px rgba(0, 0, 0, 0.2)",
                                    fontWeight: "bold",
                                    fontSize: "20px",
                                }}
                            >
                                上一页
                            </Button>
                        </div>
                    )}
                    <WarningModal
                        visible={confirmVisible}
                        handleOk={handleConfirm}
                        handleCancel={() => setConfirmVisible(false)}/>
                </div>
            </>
    );
}
