import React, { useMemo } from "react";
import { LockOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Form, Input, Modal, Select } from "antd";
import { CenterMeFlex } from "@/components/layout";
import { parseQueryString } from "@/util/query";
import { useNavigate } from "react-router-dom";
import { routePath } from "@/page/routePath";
import "./join.css";
import { useTitle } from "@/hook/useTitle";
import { CampusFormItem } from "@/components/form/CampusFormItem";
import { useApiRequest } from "@/hook/useApiRequest";
import { MemberGroupEnum } from "@/util/enum";

export const JoinPage: React.FC = () => {
  useTitle("欢迎加入IT侠");

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const { loading, sendRequest } = useApiRequest({
    path: "/member/recruit/register",
    method: "POST",
    manual: true,
    popModal: {
      onSuccess: {
        title: "提交成功",
        content: (
          <div>
            <p>欢迎加入IT侠~</p>
            <p>待管理员确认后，即可登录系统.</p>
          </div>
        ),
        okText: "返回主页",
        onOk: () => {
          navigate(`/${routePath.CUSTOM}`);
        },
      },
      onError: true,
    },
    onSuccess: () => {
      form.resetFields();
    },
    onFail: ({ code }) => {
      let message = "";
      if (code === 14) {
        message = "邀请码无效，请跟管理员确认后再试.";
      } else if (code === 15) {
        message = "登录账号名已存在，请换个试试.";
      }
      Modal.error({
        title: "提交失败",
        content: message,
        centered: true,
      });
    },
  });

  function handleSubmit(values: any) {
    sendRequest({ requestBody: values });
  }

  const initRedeemCode = useMemo(() => {
    const { redeemCode } = parseQueryString();
    if (typeof redeemCode === "string") {
      return redeemCode;
    }
    return "";
  }, []);

  return (
    <div className="join-page-container">
      <Card title="加入IT侠" className="join-page-card">
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          form={form}
          onFinish={handleSubmit}
        >
          <Form.Item
            name="redeemCode"
            label="邀请码"
            initialValue={initRedeemCode}
            rules={[{ required: true, message: "请输入邀请码" }]}
            hasFeedback
          >
            <Input placeholder="仅在加入IT侠后由管理层发放" />
          </Form.Item>

          <Form.Item
            name="realName"
            label="姓名"
            rules={[{ required: true, message: "请输入姓名" }]}
            hasFeedback
          >
            <Input placeholder="你的真实姓名" />
          </Form.Item>

          <Form.Item
            name="loginName"
            label="账号名"
            rules={[{ required: true, message: "请输入登录账号名" }]}
            hasFeedback
          >
            <Input placeholder="用于登录系统" />
          </Form.Item>

          <Form.Item
            name="studentNumber"
            label="学号"
            rules={[
              { required: true, message: "请输入你的学号" },
              {
                pattern: /^\w{9,12}$/,
                message: "本科生9位数学号，研究生9/10/12位数学号",
              },
            ]}
            hasFeedback
          >
            <Input placeholder="用于身份验证，本硕升学时更改学号" />
          </Form.Item>

          <Form.Item
            name="graduateYear"
            label="预计毕业时间"
            rules={[
              { required: true, message: "请输入你的预计毕业时间" },
              {
                pattern: /^20[234]\d$/,
                message: "4位数年份",
              },
            ]}
            hasFeedback
          >
            <Input placeholder="用于身份验证" />
          </Form.Item>

          <Form.Item
            name="email"
            label="个人邮箱"
            rules={[
              { required: true, message: "请输入你的个人邮箱" },
              { type: "email", message: "邮箱地址看起来不对..." },
            ]}
            hasFeedback
          >
            <Input placeholder="用于身份验证" />
          </Form.Item>

          <Form.Item
            name="password"
            label="密码"
            rules={[
              { required: true, message: "请填写密码" },
              {
                pattern: /^\S{8,}$/,
                message: "至少8个字符",
              },
            ]}
            hasFeedback
          >
            <Input.Password
              prefix={<LockOutlined className="form-field-icon" />}
              placeholder="密码"
            />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            label="确认密码"
            rules={[
              { required: true, message: "请填写密码" },
              {
                validator: async (rule, value) => {
                  if (form.getFieldValue("password") !== value) {
                    return Promise.reject("两次输入密码不一致");
                  }
                },
              },
            ]}
            hasFeedback
          >
            <Input.Password
              prefix={<LockOutlined className="form-field-icon" />}
              placeholder="请再次输入密码"
            />
          </Form.Item>

          <CampusFormItem />

          <Form.Item
            name="group"
            label="分组"
            required
            rules={[{ required: true, message: "请填写你的分组" }]}
          >
            <Select placeholder="请填写你的分组">
              <Select.Option value={MemberGroupEnum.GEEK}>geek</Select.Option>
              <Select.Option value={MemberGroupEnum.OP}>op</Select.Option>
              <Select.Option value={MemberGroupEnum.WEB}>web</Select.Option>
              <Select.Option value={MemberGroupEnum.NONE}>无</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="agreement1"
            label="注意事项1"
            required
            valuePropName="checked"
            initialValue={false}
            rules={[
              {
                validator: async (rule, value) => {
                  if (value !== true) {
                    return Promise.reject("请勾选注意事项");
                  }
                },
              },
            ]}
          >
            <Checkbox>
              我已了解客户在后台填写的所有信息都属社团机密和个人隐私，应受最高级别保护
            </Checkbox>
          </Form.Item>

          <Form.Item
            name="agreement2"
            label="注意事项2"
            required
            valuePropName="checked"
            initialValue={false}
            rules={[
              {
                validator: async (rule, value) => {
                  if (value !== true) {
                    return Promise.reject("请勾选注意事项");
                  }
                },
              },
            ]}
          >
            <Checkbox>
              我已了解激活后三十天内不登录系统，我将痛失接单权限
            </Checkbox>
          </Form.Item>

          <Form.Item wrapperCol={{ span: 24 }}>
            <CenterMeFlex>
              <Button type="primary" loading={loading} htmlType="submit">
                提交申请
              </Button>
            </CenterMeFlex>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};
