import React from "react";
import {Alert, Modal} from "antd";

export const WarningModal: React.FC<{
    visible: boolean;
    handleOk: () => void;
    handleCancel: () => void;
}> = ({visible, handleOk, handleCancel}) => {
    return <Modal
        title="注意！"
        open={visible}
        centered={true}
        okText="我已知晓"
        cancelText="我再想想"
        closable={false}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
            style: {
                height: "48px",
                width: "18%",
                minWidth: "90px",
                fontSize: "15px",
                borderRadius: "10px",
            },
        }}
        cancelButtonProps={{
            style: {
                height: "48px",
                width: "18%",
                minWidth: "90px",
                fontSize: "15px",
                borderRadius: "10px",
            },
        }}
        width={800}
    >
        <Alert
            type="warning"
            message={"在预约之前，IT侠希望您理解："}
            description={<div>
                任何电脑维修服务均存在<strong>损坏您的设备与数据的风险</strong>。<br/>
                若维修过程中涉及<strong>二次损坏</strong>等情况，后续维修<strong>产生费用</strong>者，需要您和IT侠<strong>共同承担</strong>。<br/>
                细则详见
                <a
                    href="https://itxia.club/service#TOS"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    预约须知和服务条款
                </a>
            </div>}
        />
    </Modal>
}