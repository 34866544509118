import React from "react";

export const DeepDarkFantasy = () => {
  return (
    <style>
      @import
      url("https://cdn.jsdelivr.net/npm/antd@4.x/dist/antd.dark.min.css");
    </style>
  );
};
