import React from "react";
import "./footer.css";

export const Footer = () => {
  return (
    <footer className="home-footer">
      <span>NJU-ITXIA 2024</span>
    </footer>
  );
};
