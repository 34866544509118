import React, { useMemo } from "react";
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";
import { UserInfoProvider } from "@/context/UserInfo";
import { routePath } from "../routePath";
import { Col, Layout, Menu, Row } from "antd";
import { Footer as MyFooter } from "@/components/footer";
import { PageNotFound } from "@/components/notFound";
import { Login } from "./login";
import { DashBoardPage } from "./dashboard";
import { HandleOrderPage } from "./handleOrder";
import { Profile } from "./profile";
import { AnnouncementManage } from "./announcementManage";
import { MemberManage } from "./memberManage";
import { useMemberContext } from "@/hook";
import { JoinPage } from "@/page/wcms/join";
import {
  CalendarOutlined,
  DashboardOutlined,
  ExperimentOutlined,
  FormOutlined,
  MenuOutlined,
  ReadOutlined,
  SettingOutlined,
  SmileOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { LogoutButton } from "@/components/logout";
import "./index.css";
import { OrderRecordPage } from "@/page/wcms/orderRecord";
import { ChartStat } from "@/page/wcms/chartStat";
import { FillInfoPage } from "@/page/wcms/fillInfo";

const { Header, Content, Footer } = Layout;

/**
 * 后台系统的router.
 * */
function WCMS() {
  return (
    <Routes>
      <Route
        path={`/`}
        element={
          <Navigate to={`/${routePath.WCMS}/${routePath.wcms.LOGIN}`} replace />
        }
      ></Route>
      <Route path={`/${routePath.wcms.LOGIN}`} element={<Login />} />
      <Route path={`/${routePath.wcms.JOIN}`} element={<JoinPage />} />
      <Route path={`/*`} element={<ItxiaPage />} />
    </Routes>
  );
}

/**
 * 登录后的router.
 * */
function WCMSRouter() {
  return (
    <Routes>
      <Route
        path={`/${routePath.wcms.DASHBOARD}`}
        element={<DashBoardPage />}
      />
      <Route
        path={`/${routePath.wcms.HANDLE_ORDER}`}
        element={<HandleOrderPage />}
      />
      <Route path={`/${routePath.wcms.EXP}`} element={<OrderRecordPage />} />
      <Route path={`/${routePath.wcms.SELF_PROFILE}`} element={<Profile />} />
      <Route
        path={`/${routePath.wcms.ANNOUNCE_MANAGE}`}
        element={<AnnouncementManage />}
      />
      <Route
        path={`/${routePath.wcms.MEMBER_MANAGE}`}
        element={<MemberManage />}
      />
      <Route path={`/${routePath.wcms.CHART_STAT}`} element={<ChartStat />} />
      <Route path={`/${routePath.wcms.FILL_INFO}`} element={<FillInfoPage />} />
      <Route path={`/*`} element={<PageNotFound />} />
    </Routes>
  );
}

/**
 * 顶部导航栏.
 * */
function NavigateBar() {
  const location = useLocation();

  const memberContext = useMemberContext();

  const isOldMember = useMemo(() => {
    return memberContext && memberContext.role === "OLD_MEMBER";
  }, [memberContext]);

  const isAdminOrSuperAdmin = useMemo(() => {
    return (
      memberContext &&
      (memberContext.role === "ADMIN" || memberContext.role === "SUPER_ADMIN")
    );
  }, [memberContext]);

  const items = useMemo(() => {
    return [
      {
        key: routePath.wcms.DASHBOARD,
        icon: <DashboardOutlined />,
        label: (
          <Link to={`/${routePath.WCMS}/${routePath.wcms.DASHBOARD}`}>
            首页
          </Link>
        ),
      },
      {
        key: routePath.wcms.HANDLE_ORDER,
        icon: <CalendarOutlined />,
        label: (
          <Link to={`/${routePath.WCMS}/${routePath.wcms.HANDLE_ORDER}`}>
            预约单
          </Link>
        ),
      },
      {
        key: routePath.wcms.EXP,
        icon: <ReadOutlined />,
        label: (
          <Link to={`/${routePath.WCMS}/${routePath.wcms.EXP}`}>经验记录</Link>
        ),
      },

      {
        key: routePath.wcms.CHART_STAT, // <- zhenxi 学长干的
        icon: <ExperimentOutlined />,
        label: (
          <Link to={`/${routePath.WCMS}/${routePath.wcms.CHART_STAT}`}>
            图表统计
          </Link>
        ),
      },
      {
        key: routePath.wcms.WTF1, // <- zhenxi 学长干的
        icon: <SettingOutlined />,
        label: "社团管理",
        children: [
          {
            key: routePath.wcms.ANNOUNCE_MANAGE,
            icon: <FormOutlined />,
            label: (
              <Link to={`/${routePath.WCMS}/${routePath.wcms.ANNOUNCE_MANAGE}`}>
                发布公告
              </Link>
            ),
          },
          {
            key: routePath.wcms.MEMBER_MANAGE,
            icon: <TeamOutlined />,
            label: (
              <Link to={`/${routePath.WCMS}/${routePath.wcms.MEMBER_MANAGE}`}>
                成员管理
              </Link>
            ),
          },
        ],

        hidden: !isAdminOrSuperAdmin, // 非管理员时隐藏
      },
      {
        key: "wtf", // <- zhenxi 学长干的
        icon: <UserOutlined />,
        style: { marginLeft: "auto" },
        children: [
          {
            key: routePath.wcms.SELF_PROFILE,
            icon: <SmileOutlined />,
            label: (
              <Link to={`/${routePath.WCMS}/${routePath.wcms.SELF_PROFILE}`}>
                个人信息
              </Link>
            ),
          },
          {
            key: "logout",
            label: <LogoutButton />,
          },
        ],
      },
    ].filter((item) => {
      if (isAdminOrSuperAdmin) return true;
      let blockList = [
        routePath.wcms.ANNOUNCE_MANAGE,
        routePath.wcms.MEMBER_MANAGE,
      ];
      if (isOldMember) {
        blockList = blockList.concat([routePath.wcms.HANDLE_ORDER]);
      }
      return !blockList.includes(item.key);
    });
  }, [isAdminOrSuperAdmin, isOldMember]);

  return (
    <Menu
      mode="horizontal"
      theme="dark"
      overflowedIndicator={
        <>
          <span className="anticon anticon-smile ant-menu-item-icon">
            <MenuOutlined />
          </span>
          <span className="ant-menu-title-content">菜单</span>
        </>
      }
      selectedKeys={[location.pathname.split("/").slice(-1)[0] || ""]}
      items={items}
    />
  );
}

function ItxiaPage() {
  return (
    <UserInfoProvider>
      <Layout id="home">
        <Header id="home-header">
          <span id="home-header-text">NJU IT侠后台管理系统</span>
          <div id="home-header-navi">
            <NavigateBar />
          </div>
        </Header>
        <Content id="home-content">
          <Row gutter={[8, 0]} justify="center" align="top">
            <Col span={24}>
              <WCMSRouter />
            </Col>
          </Row>
        </Content>
        <Footer>
          <MyFooter />
        </Footer>
      </Layout>
    </UserInfoProvider>
  );
}

export { WCMS };
