import React, {useState} from "react";
import {Alert, Form, Select} from "antd";
import { CampusEnum } from "@/util/enum";

interface CampusFormItemProps {
  hasFeedback?: boolean;
  required?: boolean;
  showHint?: boolean;
}

export const CampusFormItem: React.FC<CampusFormItemProps> = ({
  hasFeedback = true,
  required = true,
  showHint = false,
}) => {
  const [campusInfo, setCampusInfo] = useState<React.ReactNode>(
    <>
      目前我们没有浦口校区分部，有需要请前往鼓楼/仙林/苏州校区
    </>
  );

  function onCampusChange(value: any) {
    switch (value) {
      case CampusEnum.XIANLIN: {
        setCampusInfo(
          <>
            <strong>仙林校区</strong>
            的同学麻烦在详细问题描述中说明自己
            <strong>有空的时间、偏好的联系方式</strong>
          </>
        );
        break;
      }
      case CampusEnum.GULOU: {
        setCampusInfo(
          <>
            <strong>鼓楼校区</strong>
            的同学请参照
            <a
              href="https://itxia.club/service#TOS"
              target="_blank"
              rel="noopener noreferrer"
            >
              预约须知和服务条款
            </a>
          </>
        );
        break;
      }
      case CampusEnum.SUZHOU: {
        setCampusInfo(
          <>
            <strong>苏州校区</strong>
            仍在筹办中
          </>
        );
        break;
      }
      default: {
        setCampusInfo(
          <>
            目前我们没有浦口校区分部，有需要请前往鼓楼/仙林/苏州校区
          </>
        );
        break;
      }
    }
  }

  return (
    <Form.Item
      name="campus"
      label="校区"
      hasFeedback={hasFeedback}
      required={required}
      rules={[{ required: true, message: "请选择你的校区" }]}
      extra={
        showHint && <Alert
          type="warning"
          message={campusInfo}
        />
      }
    >
      <Select
        placeholder="请选择你的校区"
        onChange={onCampusChange}
      >
        <Select.Option value={CampusEnum.XIANLIN}>仙林</Select.Option>
        <Select.Option value={CampusEnum.GULOU}>鼓楼</Select.Option>
        <Select.Option value={CampusEnum.SUZHOU}>苏州</Select.Option>
      </Select>
    </Form.Item>
  );
};
