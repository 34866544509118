import React, { useState } from "react";
import { Card, Checkbox, Form, Input, Switch } from "antd";
import DatePicker from "./DatePicker";
import dayjs from "dayjs";
import { useMount } from "@/hook/useMount";
import { CheckboxValueType } from "antd/lib/checkbox/Group";

interface SearchConditionProps {
  initialValues: any;
  onConditionChange: (values: any) => void;
}

/**
 * 筛选条件.
 * */
export const SearchCondition: React.FC<SearchConditionProps> = ({
  initialValues,
  onConditionChange,
}) => {
  const [form] = Form.useForm();
  const campusOptions = [
    { label: "仙林", value: "XIANLIN" },
    { label: "鼓楼", value: "GULOU" },
    { label: "苏州", value: "SUZHOU" },
  ];
  const statusOptions = [
    { label: "等待处理", value: "PENDING" },
    { label: "正在处理", value: "HANDLING" },
    { label: "已完成", value: "DONE" },
    { label: "已取消", value: "CANCELED" },
  ];
  const [campusIndeterminate, setCampusIndeterminate] = useState(false);
  const [statusIndeterminate, setStatusIndeterminate] = useState(false);
  const [campusAllChecked, setCampusAllChecked] = useState(false);
  const [statusAllChecked, setStatusAllChecked] = useState(false);
  const [campusValues, setCampusValues] = useState<CheckboxValueType[]>([]);
  const [statusValues, setStatusValues] = useState<CheckboxValueType[]>([]);

  useMount(() => {
    const { startTime, endTime, ...rest } = initialValues;
    let orderTime = [];
    if (Array.isArray(initialValues.orderTime)) {
      //convert iso date string to day.js object
      orderTime = initialValues.orderTime.map((date: any) => {
        if (date) {
          return dayjs(date);
        } else {
          return undefined;
        }
      });
    }
    form.setFieldsValue({ ...rest, orderTime });
    setCampusValues(rest.campus);
    setStatusValues(rest.status);
    setCampusAllChecked(rest.campus.length === campusOptions.length);
    setCampusIndeterminate(!!rest.campus.length && rest.campus.length < campusOptions.length);
    setStatusAllChecked(rest.status.length === statusOptions.length);
    setStatusIndeterminate(!!rest.status.length && rest.status.length < statusOptions.length);
  });

  function onOnlyMineChange(checked: boolean) {
    form.setFieldsValue({ onlyMine: checked });
    var statusNew = statusValues;
    var campusNew = campusValues;
    if (checked && statusNew.includes("PENDING")) {
      statusNew = statusValues.filter((item) => item !== "PENDING");
    }
    form.setFieldsValue({ status: statusNew });
    setStatusValues(statusNew);
    setStatusIndeterminate(!!statusNew.length && statusNew.length < statusOptions.length);
    setStatusAllChecked(statusNew.length === statusOptions.length);
    onConditionChange({
      ...form.getFieldsValue(),
      onlyMine: checked,
      status: statusNew,
      campus: campusNew,
    });
  }

  function onCampusChange(values: any) {
    form.setFieldsValue({ campus: values });
    var statusNew = statusValues;
    setCampusValues(values);
    setCampusIndeterminate(!!values.length && values.length < campusOptions.length);
    setCampusAllChecked(values.length === campusOptions.length);
    onConditionChange({
      ...form.getFieldsValue(),
      campus: values,
      status: statusNew,
    });
  }

  function onStatusChange(values: any) {
    form.setFieldsValue({ status: values });
    var campusNew = campusValues;
    setStatusValues(values);
    setStatusIndeterminate(!!values.length && values.length < statusOptions.length);
    setStatusAllChecked(values.length === statusOptions.length);
    onConditionChange({
      ...form.getFieldsValue(),
      status: values,
      campus: campusNew,
    });
  }

  function onCampusAllCheckedChange(e: any) {
    form.setFieldsValue({ campus: e.target.checked ? campusOptions.map((item) => item.value) : [] });
    var statusNew = statusValues;
    setCampusValues(e.target.checked ? campusOptions.map((item) => item.value) : []);
    setCampusAllChecked(e.target.checked);
    setCampusIndeterminate(false);
    onConditionChange({
      ...form.getFieldsValue(),
      campus: e.target.checked ? campusOptions.map((item) => item.value) : [],
      status: statusNew,
    });
  }

  function onStatusAllCheckedChange(e: any) {
    form.setFieldsValue({ status: e.target.checked ? statusOptions.map((item) => item.value) : [], });
    var campusNew = campusValues;
    setStatusValues(e.target.checked ? statusOptions.map((item) => item.value) : [],);
    setStatusAllChecked(e.target.checked);
    setStatusIndeterminate(false);
    onConditionChange({
      ...form.getFieldsValue(),
      status: e.target.checked ? statusOptions.map((item) => item.value) : [],
      campus: campusNew,
    });
  }

  function onTextChange(e: any) {
    form.setFieldsValue({ text: e.target.value });
    onConditionChange({ ...form.getFieldsValue(), text: e.target.value, });
  }

  function onOrderTimeChange(values: any) {
    form.setFieldsValue({ orderTime: values });
    let startTime, endTime;
    if (Array.isArray(values)) {
      //convert to date string like 'yyyy-MM-dd'
      if (values[0]) {
        startTime = values[0].format("YYYY-MM-DD");
      }
      if (values[1]) {
        //+1d
        endTime = values[1].clone().add(1, "days").format("YYYY-MM-DD");
      }
    }
    onConditionChange({ ...form.getFieldsValue(), startTime, endTime, });
  }

  // noinspection NonAsciiCharacters
  return (
    <Card title="筛选">
      <Form
        form={form}
        // onValuesChange={onValuesChange}
        className="condition-container"
      >
        <Form.Item name="onlyMine" label="只看我的" valuePropName="checked">
          <Switch onChange={onOnlyMineChange} />
        </Form.Item>

        <Form.Item name="campus" label="校区">
          <div className="group-container">
            <div className="checkbox-all">
              <Checkbox
                indeterminate={campusIndeterminate}
                checked={campusAllChecked}
                onChange={onCampusAllCheckedChange}
              >
                全选
              </Checkbox>
            </div>
            <Checkbox.Group
              options={campusOptions}
              value={campusValues}
              onChange={onCampusChange}
            />
          </div>
        </Form.Item>

        <Form.Item name="status" label="状态">
          <div className="group-container">
            <div className="checkbox-all">
              <Checkbox
                indeterminate={statusIndeterminate}
                checked={statusAllChecked}
                onChange={onStatusAllCheckedChange}
              >
                全选
              </Checkbox>
            </div>
            <Checkbox.Group
              options={statusOptions}
              value={statusValues}
              onChange={onStatusChange}
            />
          </div>
        </Form.Item>

        <Form.Item name="text" label="搜索预约">
          <Input
            allowClear
            placeholder="客户姓名、电脑型号、问题描述、处理者姓名……"
            style={{}}
            onChange={onTextChange}
          />
        </Form.Item>

        <Form.Item name="orderTime" label="预约时间">
          <DatePicker.RangePicker
            ranges={{
              今天: [dayjs(), dayjs()],
              本周: [dayjs().startOf("week"), dayjs().endOf("week")],
              本月: [dayjs().startOf("month"), dayjs().endOf("month")],
            }}
            disabledDate={(date) => {
              return date.isAfter(dayjs());
            }}
            style={{}}
            onChange={onOrderTimeChange}
          />
        </Form.Item>
      </Form>
    </Card>
  );
};
