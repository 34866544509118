import React, { useEffect, useState } from "react";
import { Loading } from "@/components/loading";
import { Card, Col, Row, Statistic } from "antd";

/**
 * 将分钟数转换为“天，小时，分钟”格式
 * */
function formatMinutes(minutes: number): string {
  if (minutes < 0) {
    return "传感器未部署/未连接……";
  }
  if (minutes < 60) {
    return `已有 ${minutes} 分钟没有社员活动`;
  }
  if (minutes < 60 * 24) {
    let hours = Math.floor(minutes / 60);
    let minutesLeft = minutes % 60;
    if (minutesLeft === 0) {
      return `已有 ${hours} 小时整没有社员活动`;
    }
    return `已有 ${hours} 小时 ${minutesLeft} 分钟没有社员活动`;
  } else {
    let days = Math.floor(minutes / (60 * 24));
    let hours = Math.floor((minutes % (60 * 24)) / 60);
    let minutesLeft = minutes % 60;
    if (hours === 0 && minutesLeft === 0) {
      return `已有 ${days} 天整没有社员活动`;
    }
    if (hours === 0) {
      return `已有 ${days} 天零 ${minutesLeft} 分钟没有社员活动`;
    }
    if (minutesLeft === 0) {
      return `已有 ${days} 天零 ${hours} 小时没有社员活动`;
    }
    return `已有 ${days} 天零 ${hours} 小时 ${minutesLeft} 分钟没有社员活动`;
  }
}

/**
 * 工作室状态组件.
 * */
export const WorkshopPresence: React.FC = () => {
  const [time, setTime] = useState<number | null>(null);

  useEffect(() => {
    fetch("/api/workshop_presence")
    .then((response) => response.text())
    .then((text) => {
      setTime(Number(text));
    })
    .catch((error) => console.error(error));
  }, []);

  if (time === null) {
    return <Loading />;
  }

  return (
    <Card>
      <h1>工作室状态</h1>
      <Row>
        <Col span={16}>
          <Statistic
            title="仙林校区敬文学生活动中心412"
            value={time === 0 ? "现有社员正在活动！！" : formatMinutes(time)}
            valueStyle={{ color: time === 0 ? "green" : "red" }}
          />
        </Col>
      </Row>
    </Card>
  );
};
