let client_id;
let redirect_host;

if (
  window.location.host === "nju.itxia.cn" ||
  window.location.host.indexOf("prod") !== -1
) {
  client_id = "102017737";
  redirect_host = "nju.itxia.cn";
} else if (
  window.location.host === "test.itxia.cn" ||
  window.location.host.indexOf("test") !== -1
) {
  client_id = "102021032";
  redirect_host = "test.itxia.cn";
} else {
  client_id = "102050366";
  redirect_host = "rolling.itxia.cn";
}

const config = {
  network: {
    api: {
      prefix: window.location.origin + "/api",
      origin: window.location.origin,
    },
  },
  oauth: {
    qq: `https://graph.qq.com/oauth2.0/authorize?response_type=token&client_id=${client_id}&redirect_uri=https://${redirect_host}/xoauth&scope=get_user_info`,
  },
  etc: {
    name: "ITXIA后台系统",
    footer: {
      text: "南京大学IT侠-web组 © 2024",
    },
  },
};

export { config };
