import React, { useEffect } from "react";
import { Loading } from "@/components/loading";
import { Navigate, useNavigate } from "react-router-dom";
import { routePath } from "@/page/routePath";
import { Modal } from "antd";
import { useApiRequest } from "@/hook/useApiRequest";
import { usePersistFn } from "@/hook/usePersisFn";
import { CampusEnum, MemberGroupEnum, MemberRoleEnum } from "@/util/enum";

export interface WhoamiData {
  readonly campus: CampusEnum;
  readonly group: MemberGroupEnum;
  readonly _id: string;
  readonly loginName: string;
  readonly realName: string;
  readonly studentNumber: string;
  readonly graduateYear: number;
  readonly role: MemberRoleEnum;
  readonly email: string;
  readonly qq: string;
  readonly emailNotification: any;
  readonly requirePasswordReset: boolean;
  readonly needUpdateInfo: boolean;
  refresh: () => void;
}

const UserInfoContext = React.createContext<WhoamiData | null>(null);

/**
 * 提供当前登录用户信息的context.
 */
function UserInfoProvider(props: any) {
  const { loading, code, payload, error, sendRequest } = useApiRequest({
    path: "/whoami",
  });

  const navigate = useNavigate();

  const refresh = usePersistFn(sendRequest);

  useEffect(() => {
    if (payload) {
      if ((payload as WhoamiData).requirePasswordReset) {
        Modal.warning({
          title: "请尽快修改密码",
          content: "密码已过期，请尽快修改.",
          centered: true,
          okText: "马上去修改",
          onOk: () => {
            navigate(`/${routePath.WCMS}/${routePath.wcms.SELF_PROFILE}`);
          },
          cancelText: "以后再说",
          okCancel: true,
        });
      } else if ((payload as WhoamiData).needUpdateInfo) {
        Modal.warning({
          title: "请补充您的个人信息",
          content: "由于系统更新，您需要补充您的个人信息。",
          centered: true,
          okText: "马上去补充",
          onOk: () => {
            navigate(`/${routePath.WCMS}/${routePath.wcms.FILL_INFO}`);
          },
          cancelText: "你莫得选择",
          onCancel: () => {
            navigate(`/${routePath.WCMS}/${routePath.wcms.FILL_INFO}`);
          },
          okCancel: true,
        });
      }
    }
  }, [navigate, payload]);

  if (loading && code !== 0) {
    return <Loading />;
  }

  //未登录，跳转到登录页
  if (error || code !== 0) {
    return (
      <Navigate to={`/${routePath.WCMS}/${routePath.wcms.LOGIN}`} replace />
    );
  }

  return (
    <UserInfoContext.Provider value={{ refresh, ...payload }}>
      {props.children}
    </UserInfoContext.Provider>
  );
}

export { UserInfoContext, UserInfoProvider };
