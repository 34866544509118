import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { routePath } from "./routePath";
import { WCMS } from "./wcms";
import { CustomSystem } from "./custom";
import { PageNotFound } from "@/components/notFound";
import { OAuthPage } from "@/page/oauth";

/**
 * 整个应用的router.
 * */
export const Main = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={`/`}
          element={<Navigate to={`/${routePath.CUSTOM}`} replace />}
        />
        <Route path={`/${routePath.WCMS}/*`} element={<WCMS />} />
        <Route path={`/${routePath.CUSTOM}/*`} element={<CustomSystem />} />
        <Route path={`/${routePath.OAUTH}/*`} element={<OAuthPage />} />
        <Route path={`/*`} element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};
